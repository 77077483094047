<template>
  <base-layout title="Setup Environment">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item
              :router-link="{ name: p.routeName }"
              v-for="(p, i) in appPages"
              :key="i"
              class="link"
            >
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>
                <h2>{{ p.title }}</h2>
                <p> {{ p.description }} </p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";

import { bookOutline, bookSharp } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  data() {
    return {
      appPages: [
        {
          title: "Session",
          description: "View and create Academic session",
          routeName: "session",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Classes",
          description: "Combine standards and sections to create class",
          routeName: "class",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Sections",
          description: "View and create class sections",
          routeName: "section",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Standards",
          description: "View and create class standards",
          routeName: "standard",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Transfer students",
          description: "Transfer students to another class",
          routeName: "transfer.student",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Delisted students",
          description: "De-list & Re-enlist students",
          routeName: "delist.student",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
